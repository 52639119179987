import React from 'react';
import * as Styled from './slpHeaderWithoutProductNameStyles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import useContentfulAssets from '../../hooks/useContentfulAssets';

const SlpHeaderWithoutProductName = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [INLINES.ASSET_HYPERLINK]: (node, children) => (
        <Styled.LinkStyle textAlign="left">{children}</Styled.LinkStyle>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        return asset?.node?.file?.url?.includes('image') ? (
          <Styled.ImageStyle
            src={asset?.node?.file?.url}
            alt={asset?.node?.title}
          />
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };

  return (
    <Styled.HeaderContainer>
      <Styled.Header>
        <Styled.ImageStyle src={sectionData?.image?.file?.url} />
        {sectionData?.listDetails &&
          documentToReactComponents(
            JSON.parse(sectionData?.listDetails?.raw),
            optionsMainStyle
          )}
      </Styled.Header>
    </Styled.HeaderContainer>
  );
};
export default SlpHeaderWithoutProductName;
